import {EnvironmentType} from '../app/core/services/auth-service/types';

export const environment = {
  production: false,
  auth: {
    authServiceUrl: 'https://sbx2-auth.globalpoll.com.ua',
    authServiceApiUrl: 'https://sbx2-auth-api.globalpoll.com.ua',
    crmUrl: 'https://sbx2.globalpoll.com.ua',
  },
  clientId: '6b093df2a5124ec3a6f225e36ba06964_crm',
  clientClassifier:
    'ffaac073-d9ad-43c6-9f40-906daf45c3710e1b604e-d1cf-4bbb-b192-fb5257e7d726',
  type: EnvironmentType.SANDBOX
};
