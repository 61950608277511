export interface AuthData {
  clientId: string;
  clientClassifier: string;
  entryUrl: string;
}

export enum userClassifier {
  CRM_WORKER = 'CRM_WORKER',
  SCHOOL_WORKER = 'SCHOOL_WORKER',
}

export enum EnvironmentType {
  ISO_SANDBOX = 'ISO_SANDBOX',
  ISO_STAGE = 'ISO_STAGE',
  PROD = 'PROD',
  SANDBOX = 'SANDBOX',
  STAGE = 'STAGE',
  LOCAL = 'LOCAL',
  UAT = 'UAT',
  KHR_AWS_PROD = 'KHR_AWS_PROD',
  KHR_PROD = 'KHR_PROD'
}
